import { device } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: #323747;
  min-height: 360px;

  h3 {
    font-family: "Sora", Helvetica, sans-serif;
    font-style: normal;
    font-weight: 650;
    color: #E3E1D3;
    margin-bottom: 30px;

    @media ${device.tablet} {
      color: #E3E1D3;
      margin-bottom: 43px;
    }

    @media ${device.desktopLG} {
      line-height: 48px;
      margin-bottom: 16px;
      margin-bottom: 43px;
    }

    @media ${device.desktopXL} {
      margin-bottom: 43px;
    }
  }
  .summary-content {
    input[type='text'] {
      border: 1px solid #6A6C72;
      color: ${white}
    }
  }
`
