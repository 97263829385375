import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

import Obviously from '../../../../../../styles/fonts/obviously_wide.otf'

export const Section = styled.section`
  @font-face {
    font-family: 'Obviously';
    src: url(${Obviously}) format('opentype');
    font-display: swap;
  }

  align-items: center;
  background-color: #E3E1D3;
  display: flex;
  min-height: 360px;
  width: 100%;
  padding-top: 48px;

  .img-estecialistas-trabalhando {
    margin-top: 40px;
  }

  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-686-win/image.webp');
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${device.desktopLG} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-686-win/image.webp');
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media ${device.desktopXL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-1440-win/image.webp');
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 810px;
  }

  h2 {
    font-family: 'Obviously';
    font-style: normal;
    color: #323747;
    margin-bottom: 16px;
    margin-top: 24px;

    @media ${device.tablet} {
      color: #323747;
    }

    @media ${device.desktopLG} {
      font-family: 'Obviously';
      margin-bottom: 16px;
    }

    @media ${device.desktopXL} {
      font-family: 'Obviously';
      margin-bottom: 24px;
    }
  }

  p {
    font-family: "Sora", Helvetica, sans-serif;
    font-style: normal;
    color: #323747;
    margin-left: 17px;
    margin-bottom: 0px;

    @media ${device.tablet} {
      color: #323747;
    }

    @media ${device.desktopLG} {
      line-height: 23px;
    }
    
    @media ${device.desktopXL} {
      line-height: 28px;
    }
  }
`
