import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

import Obviously from '../../../../../../styles/fonts/obviously_wide.otf'

export const Section = styled.section`
  @font-face {
    font-family: 'Obviously';
    src: url(${Obviously}) format('opentype');
    font-display: swap;
  }

  align-items: center;
  position: relative;
  background-color: #B75432;
  display: flex;
  min-height: 284px;
  width: 100%;
  padding-top: 0;

  @media ${device.tablet} {
    background-color: #E3E1D3;
  }

   @media ${device.desktopLG} {
    background-color: #E3E1D3;
  }

  @media ${device.desktopXL} {
    min-height: 460px;
  }

  h2 {
    font-family: 'Obviously';
    font-style: normal;
    font-weight: 640;
    color: #E3E1D3;
    margin-bottom: 16px;

    @media ${device.tablet} {
      color: #323747;
    }

    @media ${device.desktopLG} {
      font-family: 'Obviously';
      font-size: 26px;
      font-weight: 640;
      line-height: 35px;
      margin-bottom: 16px;
      width: 616px;
    }

    @media ${device.desktopXL} {
      font-family: 'Obviously';
      font-size: 36px;
      font-weight: 640;
      line-height: 48px;
      margin-bottom: 24px;
      width: 640px;
    }
  }

  p {
    font-family: "Sora", Helvetica, sans-serif;
    font-style: normal;
    line-height: 20px;
    color: #E3E1D3;

     @media ${device.tablet} {
      color: #323747;
    }

    @media ${device.desktopLG} {
      width: 616px;
    }
    
    @media ${device.desktopXL} {
      width: 748px;
    }
  }
`

export const ZGraph = styled.div`
  display: none;

  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-768-win/image.webp');
    position: absolute;
    display: block;
    width: 372px;
    height: 341px;
    top: 0;
    right: 0;
  }

   @media ${device.desktopLG} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-1024-win/image.webp');
    background-repeat: no-repeat;
    background-position-x: right;
    position: absolute;
    display: block;
    width: 415px;
    height: 379px;
    top: 0;
    right: 0;
  }

  @media ${device.desktopXL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-1440-win/image.webp');
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: contain;
    width: 670px;
    height: 613px;
  }
`
