import { device } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'
import styled from 'styled-components'

import ObviouslyBold from '../../../../../../styles/fonts/obviously_wide.otf'
import ObviouslyMedium from '../../../../../../styles/fonts/obviously_wide_medium.otf'

export const Section = styled.section`
  @font-face {
    font-family: 'ObviouslyBold';
    src: url(${ObviouslyBold}) format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'ObviouslyMedium';
    src: url(${ObviouslyMedium}) format('opentype');
    font-display: swap;
  }

  align-items: center;
  background-color: #E3E1D3;
  display: flex;
  min-height: 874px;
  padding-top: 0;
  position: relative;

  @media ${device.tablet} {
    min-height: 630px;
    overflow: hidden;
  }

  @media ${device.desktopXL} {
    overflow: visible;
    min-height: 721px;
  }

  .OGraph {
    position: absolute;
    bottom: 22%;
    right: 0;

    @media ${device.tablet} {
      top: 0px;
    }

    @media ${device.desktopXL} {
      top: -350px;
      left: 345px;
    }
  }

  .SGraph {
    position: absolute;
    left: 0;
    bottom: 0;

    @media ${device.tablet} {
      bottom: -70px;
    }

    @media ${device.desktopLG} {
      bottom: 0;
    }
  }

  h3 {
    font-family: 'ObviouslyBold';
    font-style: normal;
    font-weight: 640;
    color: #323747;
    margin-bottom: 32px;

    @media ${device.tablet} {
      color: #323747;
      width: 338px
    }

    @media ${device.desktopLG} {
      font-family: 'ObviouslyBold';
      font-weight: 640;
      margin-bottom: 16px;
      width: 404px;
    }

    @media ${device.desktopXL} {
      font-family: 'ObviouslyBold';
      font-weight: 640;
      margin-bottom: 24px;
      width: 640px;
    }
  }

  .subtitle {
    @media ${device.tablet} {
      width: 456px;
    }
  }

  p {
    font-family: "Sora", Helvetica, sans-serif;
    font-style: normal;
    color: #323747;

    @media ${device.tablet} {
      color: #323747;
    }
  }
`

export const Card = styled.div`
  padding: 20px;
  border: 1px solid #B75432;
  box-sizing: border-box;
  border-radius: 12px;
  width: 312px;
  background-color: #E3E1D3;

  @media ${device.tablet} {
    min-height: 300px;
  }

  @media ${device.desktopLG} {
    min-height: 256px;
    width: 456px;
  }

  @media ${device.desktopLG} {
    min-height: 238px;
    width: 469px;
  }

  .whatsapp path {
    fill: #B75432;
  }

  h4 {
    font-family: 'ObviouslyMedium';
    color: #B75432;
    font-weight: 650;
  }

  p {
    color: #323747;
  }

  a {
    color: #323747;

    &:hover {
      text-decoration: none;
      color: ${white};
      cursor: pointer;
    }
  }
`
